import { SupportedFetchExtensions, RESTmethods } from "./Utils";

const genericErrorMessage = 'Network response was not OK';

export const fetchDataFromURL = async ({
  url,
  extension = SupportedFetchExtensions.json,
  restMethod = RESTmethods.GET,
  body = null,
  includesContentTypeHeader = true
}) => {
  try {
    const fetchOptions = {
      method: restMethod,
      ...(body && { body: JSON.stringify(body) }),
      headers: {}
    };

    if (includesContentTypeHeader) {
      switch (extension) {
        case SupportedFetchExtensions.json:
          fetchOptions.headers["Content-Type"] = "application/json; charset=UTF-8";
          break;
        case SupportedFetchExtensions.csv:
          fetchOptions.headers["Content-Type"] = "text/csv; charset=UTF-8";
          break;
        default:
          break;
      }
    }

    const response = await fetch(url, fetchOptions);

    // Case: 500
    if (response.status === 500) {
      return Promise.reject(new Error('The server encountered an error. Please try again later.'));
    }

    // Proceed with case OK 204
    if (response.status === 204) {
      return true;
    }

    // All other errors
    if (!response.ok) {
      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const errorData = await response.json();
        return Promise.reject(new Error(errorData.message || genericErrorMessage));
      } else {
        const errorText = await response.text();
        return Promise.reject(new Error(errorText || genericErrorMessage));
      }
    }

    // OK
    switch (extension) {
      case SupportedFetchExtensions.json:
        return await response.json();
      case SupportedFetchExtensions.csv:
        return await response.text();
      default:
        return response;
    }
  } catch (error) {
    // Check if it's a network error
    if (error.message === 'Failed to fetch') {
      throw new Error('Unable to connect to the server. Please check your internet connection and try again.');
    }

    // For other errors, preserve the original message
    throw new Error(error.message);
  }

};

const keyMap = {
  start_date: "sd",
  end_date: "ed",
  location_method: "lm",
  location_type: "lt"
};

export const shortenKeys = (dataArray) => {
  return dataArray.map(data =>
    Object.fromEntries(Object.entries(data).map(([key, value]) => [keyMap[key] || key, value]))
  );
};

export const expandKeys = (dataArray) => {
  return dataArray.map(data =>
    Object.fromEntries(Object.entries(data).map(([key, value]) => {
      const originalKey = Object.keys(keyMap).find(k => keyMap[k] === key) || key;
      return [originalKey, value];
    }))
  );
};