import { createContext, useState, useContext, useEffect, useCallback } from "react";
import { categorizedLocationTypes } from "../Utils/GlobalVariables";
import { React } from "react";
import { AlertSeverity, useNotificationContext } from "./NotificationContext";
import { SurveyMetadataContext } from "./SurveyMetadataContext";
import { generateEmptyTimeBlocksArray, getHourStringFromDateString } from "../Utils/TimeBlockUtils";

export const TimeBlocksContext = createContext();

export const TimeBlocksProvider = ({ children }) => {
    const { pseudonym, surveyDate } = useContext(SurveyMetadataContext);
    const { setShowNotification, setMessage, setSeverity } = useNotificationContext();

    const [timeBlocks, setTimeBlocks] = useState([]);
    const [isTouching, setIsTouching] = useState(false); // Track global touch state
    const [allBlocksFilled, setAllBlocksFilled] = useState(false);

    useEffect(() => {
        if (!surveyDate || !pseudonym) return;

        setTimeBlocks(generateEmptyTimeBlocksArray(surveyDate));
    }, [surveyDate, pseudonym]);

    useEffect(() => {
        setAllBlocksFilled(timeBlocks.filter((timeBlock) => timeBlock.location_type !== null).length === timeBlocks.length);
    }, [timeBlocks]);

    const handleRowActivate = useCallback((rowIndex, blockIndex) => {
        const thisTimeBlock = timeBlocks[rowIndex];
        const newLocationType = categorizedLocationTypes[blockIndex];

        const updatedBlocks = [...timeBlocks];
        updatedBlocks[rowIndex].location_type = newLocationType.id;
        setTimeBlocks(updatedBlocks);

        setShowNotification(true);
        setMessage(`
            ${newLocationType.name}: 
            ${getHourStringFromDateString(thisTimeBlock.start_date)}
             - ${getHourStringFromDateString(thisTimeBlock.end_date)}
        `);
        setSeverity(AlertSeverity.primary);
    }, [timeBlocks, setShowNotification, setMessage, setSeverity]);

    return (
        <TimeBlocksContext.Provider value={{
            timeBlocks, setTimeBlocks,
            isTouching, setIsTouching,
            allBlocksFilled, handleRowActivate,
        }}>
            {children}
        </TimeBlocksContext.Provider>
    );
};

export const useTimeBlocks = () => useContext(TimeBlocksContext);
