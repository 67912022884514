import { useState, useEffect, createContext, useMemo } from 'react';

export const SurveyMetadataContext = createContext();

export function SurveyMetadataProvider({ children }) {
    const [pseudonym, setPseudonym] = useState(null);
    const [surveyDate, setSurveyDate] = useState(null);
    const [isSample, setIsSample] = useState(false);

    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const pseudonymParam = params.get('pseudonym');
        const dateParam = params.get('date');
        const sampleParam = params.get('isSample');

        if (pseudonymParam) {
            setPseudonym(pseudonymParam);
        }
        if (dateParam) {
            setSurveyDate(dateParam);
        }
        if (sampleParam) {
            setIsSample(sampleParam === 'true');
        }
    }, []);

    const providerValue = useMemo(() => ({
        pseudonym, setPseudonym,
        surveyDate, setSurveyDate,
        isSample, setIsSample
    }), [pseudonym, surveyDate, isSample]);

    return (
        <SurveyMetadataContext.Provider value={providerValue}>
            {children}
        </SurveyMetadataContext.Provider>
    );
}
