import { Accordion, AccordionSummary, AccordionDetails, Typography, Stack, styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    minHeight: '2rem',
    maxHeight: '2rem',
    '&.Mui-expanded': {
        minHeight: '2rem',
        maxHeight: '2rem',
    }
}));

const CollapsibleSection = ({ label, secondaryLabel, children, defaultExpanded, id, expanded, handleChange }) => {
    return (
        <Accordion
            defaultExpanded={defaultExpanded}
            expanded={expanded === id}
            onChange={handleChange(id)}
            disableGutters
        >
            <StyledAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 2,
                    backgroundColor: "background.paper",
                    boxShadow: 1,
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ width: "100%" }}
                >
                    <Typography fontWeight={600}>{label}</Typography>
                    <Typography variant="caption" textTransform="uppercase" color="text.secondary">
                        {secondaryLabel}
                    </Typography>
                </Stack>
            </StyledAccordionSummary>
            <AccordionDetails sx={{ pb: 1 }}>{children}</AccordionDetails>
        </Accordion>
    );
};

export default CollapsibleSection;
