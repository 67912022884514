import { Box, Stack, Typography } from "@mui/material";
import CollapsibleSection from "./CollapsibleSection";
import Row from "./Row";
import { categorizedLocationTypes } from "../Utils/GlobalVariables";
import { TIME_BLOCK_WIDTH } from '../Utils/TimeBlockUtils';
import { formatToShortDateString, INTERVALS_PER_HOUR } from '../Utils/TimeBlockUtils';
import { CollapsibleSections } from '../Utils/TimeBlockUtils';
import { useTimeBlocks } from "../ContextProviders/TimeBlocksContext";
import { getHourStringFromDateString } from "../Utils/TimeBlockUtils";
import { useContext, useState } from "react";
import { SurveyMetadataContext } from "../ContextProviders/SurveyMetadataContext";
import { addDays } from "date-fns";

const LocationSurveyGrid = () => {
    const { timeBlocks, handleRowActivate, isTouching, setIsTouching } = useTimeBlocks();
    const { surveyDate } = useContext(SurveyMetadataContext);
    const surveyDateObject = new Date(surveyDate);

    const handleTouchStart = () => {
        setIsTouching(true);
    };

    const handleTouchEnd = () => {
        setIsTouching(false);
    };

    const handleTouchMove = (e) => {
        if (!isTouching) return;

        const touch = e.touches[0];
        const element = document.elementFromPoint(touch.clientX, touch.clientY);

        if (element && element.dataset.rowIndex && element.dataset.blockIndex) {
            const rowIndex = parseInt(element.dataset.rowIndex, 10);
            const blockIndex = parseInt(element.dataset.blockIndex, 10);
            handleRowActivate(rowIndex, blockIndex); // Activate the touched block
        }
    };

    const [expanded, setExpanded] = useState(0);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    }

    return (
        <Stack
            direction="column"
            sx={{
                overflowY: "auto", // Enables scrolling
                height: "100%", // Ensures the container takes up available space
            }}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onTouchMove={handleTouchMove} // Handle touch move globally
            gap={1}
        >
            {CollapsibleSections.map((section, sectionIndex) => {

                const timeBlocksCount = timeBlocks.slice(
                    section.startHour * INTERVALS_PER_HOUR,
                    section.endHour * INTERVALS_PER_HOUR
                ).filter((timeBlock) => timeBlock.location_type !== null).length;

                const blocksInThisSection = (section.endHour - section.startHour) * INTERVALS_PER_HOUR;
                const progressPercentage = Math.round((timeBlocksCount / blocksInThisSection) * 100);

                return (
                    <CollapsibleSection
                        key={sectionIndex}
                        label={
                            `${surveyDate ?
                                formatToShortDateString(addDays(surveyDateObject, section.addDays || 0))
                                : ""
                            }, ${section.label}`
                        }
                        secondaryLabel={`${progressPercentage}% done`}
                        defaultExpanded={sectionIndex === 0} // Expand the first section by default
                        id={sectionIndex}
                        expanded={expanded}
                        handleChange={handleChange}
                    >
                        <Stack spacing={3} direction="row" sx={{ mb: 1 }}
                            justifyContent="center"
                        >
                            <Box sx={{ width: "4rem" }} />
                            <Stack spacing={0} direction="row" alignItems="end">
                                {categorizedLocationTypes.map((locationType) => (
                                    <Typography
                                        key={locationType.id}
                                        variant="caption"
                                        fontWeight={600}
                                        sx={{
                                            userSelect: "none",
                                            width: TIME_BLOCK_WIDTH + 2 * 8,
                                            wordWrap: "break-word",
                                            lineHeight: 1
                                        }}
                                    >
                                        {locationType.name}
                                    </Typography>
                                ))}
                            </Stack>
                        </Stack>

                        {Array.from({ length: section.endHour - section.startHour }, (_, i) => section.startHour + i).map((hourIndex) => (
                            <Stack
                                key={hourIndex}
                                direction="row"
                                alignItems="start"
                                justifyContent="center"
                                spacing={1}
                            >
                                {/* Hour label */}
                                <Typography
                                    variant="body2"
                                    sx={{
                                        userSelect: "none",
                                        width: "4rem", textAlign: "right", marginTop: "-0.5rem !important"
                                    }}
                                >
                                    {getHourStringFromDateString(timeBlocks[hourIndex * INTERVALS_PER_HOUR]?.start_date)}
                                </Typography>

                                {/* Rows for each hour */}
                                <Stack key={hourIndex} direction="column">
                                    {Array.from({ length: INTERVALS_PER_HOUR }).map((_, minuteIndex) => {
                                        const rowIndex = hourIndex * INTERVALS_PER_HOUR + minuteIndex;
                                        return (
                                            <Row
                                                key={rowIndex}
                                                rowIndex={rowIndex}
                                                selectedBlock={timeBlocks[rowIndex]} // Pass active block index for this row
                                                onActivate={(blockIndex) =>
                                                    handleRowActivate(rowIndex, blockIndex)
                                                }
                                            />
                                        );
                                    })}
                                </Stack>
                            </Stack>
                        ))}
                    </CollapsibleSection>
                );
            })}
        </Stack>
    );
};

export default LocationSurveyGrid;
