import { React, useMemo, useContext, useState } from "react";

import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material/";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import ThemePreferences from "./Themes/ThemePreferences";
import CustomThemes from "./Themes/CustomThemes";

import { PreferenceContext } from "./ContextProviders/PreferenceContext";
import LocationSurveyGrid from "./Components/LocationSurveyGrid";
// import SummarySection from "./Components/SummarySelection";

import SendIcon from '@mui/icons-material/Send';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { useTimeBlocks } from "./ContextProviders/TimeBlocksContext";
import { fetchDataFromURL, shortenKeys } from "./API/ApiFetch";
import { API_URL } from "./Utils/GlobalVariables";
import { RESTmethods } from "./API/Utils";
import { SurveyMetadataContext } from "./ContextProviders/SurveyMetadataContext";
import { AlertSeverity, useNotificationContext } from "./ContextProviders/NotificationContext";
import SnackbarNotification from "./Components/SnackbarNotification";
import { generateEmptyTimeBlocksArray } from "./Utils/TimeBlockUtils";

// Create theme design tokens based on theme preference
const getDesignTokens = (themePreference) => ({
  palette: {
    mode: themePreference,
    ...(themePreference === ThemePreferences.dark
      ? {
        ...CustomThemes.dark.palette,
        ...CustomThemes.universal.palette,
        typography: CustomThemes.universal.palette,
      }
      : {
        ...CustomThemes.light.palette,
        ...CustomThemes.universal.palette,
        typography: CustomThemes.universal.palette,
      }),
  },
});

function App() {
  const { themePreference } = useContext(PreferenceContext);

  // Create theme using getDesignTokens
  const theme = useMemo(
    () => createTheme(getDesignTokens(themePreference)),
    [themePreference]
  );

  // set backgroundColor of 'body' element depending on theme.
  // this is to set bg-color of left/right padding on landscape iOS devices
  document.body.style.background = theme.palette.customAlternateBackground;

  const { timeBlocks, setTimeBlocks, allBlocksFilled } = useTimeBlocks();
  const { pseudonym, surveyDate, isSample } = useContext(SurveyMetadataContext);

  const { setShowNotification, setMessage, setSeverity } = useNotificationContext();

  const [isSending, setIsSending] = useState(false);
  const [hasSent, setHasSent] = useState(false);

  const handleClearAll = () => {
    setTimeBlocks(generateEmptyTimeBlocksArray(surveyDate)); // Clear all blocks
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarNotification />
      <Stack
        sx={{
          p: 1,
          height: "100dvh",
          backgroundColor: "customBackground",
        }}
      >
        <Typography variant="body2" color="text.secondary">
          <b>{isSample ? "Sample survey" : `Survey: ${surveyDate}`}</b>
        </Typography>


        <Typography variant="body2" color="text.secondary">
          <b>Touch/Swipe the blocks</b> to select the blocks
        </Typography>

        <Stack direction="row" justifyContent="end">
          <Button
            sx={{
              py: 0, width: "fit-content"
            }}
            size="small"
            variant="text"
            color="error"
            onClick={handleClearAll}
          >
            Clear All
          </Button>
        </Stack>

        {/* Make the middle section scrollable */}
        <Box
          sx={{
            flexGrow: 1,
            my: 1,
            overflow: "auto", // Enables scrolling for overflow content
          }}
        >
          {!hasSent ? <LocationSurveyGrid /> : null}
        </Box>

        {/* Sticky bottom section */}
        <Stack
          sx={{
            position: "sticky",
            bottom: 0, // Sticks to the bottom of the viewport
            zIndex: 1,
            gap: 1,
            backgroundColor: "inherit", // To blend with the background
          }}
        >

          <Button
            variant="contained"
            fullWidth
            startIcon={isSending ? null : (allBlocksFilled ? (hasSent ? <CheckCircleOutlineIcon /> : <SendIcon />) : null)}
            disabled={!allBlocksFilled || hasSent}
            size="small"
            sx={{
              height: "2rem"
            }}
            onClick={() => {
              setIsSending(true);

              if (isSample) {
                // Emulate a send if isSample === true
                setTimeout(() => {
                  setIsSending(false);
                  setShowNotification(true);
                  setMessage("Success!");
                  setSeverity(AlertSeverity.success);
                  setHasSent(true);
                }, 2000);
              } else {
                fetchDataFromURL({
                  url: `${API_URL}/locations/${pseudonym}`,
                  restMethod: RESTmethods.POST,
                  body: shortenKeys(timeBlocks)
                }).then((data) => {
                  console.log(data);
                  setShowNotification(true);
                  setMessage("Success, closing...");
                  setSeverity(AlertSeverity.success);
                  setHasSent(true);

                  setTimeout(() => {
                    window.webkit.messageHandlers.didCompleteSurvey.postMessage("SurveyCompleted");
                  }, 1000);

                }).catch((error) => {
                  console.log(error);
                  setShowNotification(true);
                  setMessage(error.message || "An error occured, please try again.");
                  setSeverity(AlertSeverity.error);
                }).finally(() => {
                  setIsSending(false)
                })
              }
            }}
          >
            {isSending ? <CircularProgress sx={{ color: "white" }} size="1.5rem" /> :
              (allBlocksFilled ? (hasSent ? "SURVEY SENT" : "SEND") : "COMPLETE THE SURVEY TO SEND")
            }
          </Button>
        </Stack>
      </Stack>

    </ThemeProvider>
  );
}

export default App;
