import React from "react";
import { Box } from "@mui/material";
import { LocationTypes } from "../Utils/GlobalVariables";
import { TIME_BLOCK_HEIGHT, TIME_BLOCK_WIDTH } from '../Utils/TimeBlockUtils';
import { INTERVALS_PER_HOUR } from '../Utils/TimeBlockUtils';
import { grey } from "@mui/material/colors";

const TimeBlock = ({ color, isActive, onActivate, ...props }) => {
    const rowIndex = props["data-row-index"];

    const hourlyBorderTop = rowIndex % INTERVALS_PER_HOUR === 0;

    return (
        <Box
            {...props} // Spread additional props like data-row-index and data-block-index
            sx={{
                width: TIME_BLOCK_WIDTH,
                height: TIME_BLOCK_HEIGHT,
                backgroundColor: isActive ? color : LocationTypes.uncategorized.color,
                border: '0.5px solid',
                borderColor: grey[100],
                borderTopWidth: hourlyBorderTop ? '1.5px' : '0.5px',
                borderBottomWidth: false ? '1.5px' : '0px',
                cursor: 'pointer',
                touchAction: 'none', // Prevent scrolling while swiping
            }}
            onMouseDown={onActivate}
            onMouseEnter={(e) => e.buttons === 1 && onActivate()}
            onTouchStart={(e) => {
                e.preventDefault();
                onActivate();
            }}
        />
    );
};

export default TimeBlock;
