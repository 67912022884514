import { Stack } from "@mui/material";
import TimeBlock from "./TimeBlock";
import { categorizedLocationTypes } from "../Utils/GlobalVariables";

const Row = ({ rowIndex, selectedBlock, onActivate }) => {
    const handleActivate = (index) => {
        onActivate(index);
    };

    return (
        <Stack direction="row" spacing={2} sx={{
            touchAction: 'none',
            width: 'fit-content'
        }}>
            {categorizedLocationTypes.map((type, index) => (
                <TimeBlock
                    key={index}
                    color={type.color}
                    isActive={selectedBlock?.location_type === type.id}
                    onActivate={() => handleActivate(index)}
                    data-row-index={rowIndex} // Add data attributes for touch detection
                    data-block-index={index}
                />
            ))}
        </Stack>
    );
};

export default Row;
