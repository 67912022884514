export const DATE_HOUR_DELIMITER = "T";

export const CollapsibleSections = [
    { label: "20:00 - 23:59", startHour: 20, endHour: 24, addDays: -1 },
    { label: "00:00 - 09:59", startHour: 0, endHour: 10, addDays: 0 },
    { label: "10:00 - 19:59", startHour: 10, endHour: 20, addDays: 0 },
];

export const TIME_BLOCK_INTERVAL_MINUTES = 30;
export const INTERVALS_PER_HOUR = 60 / TIME_BLOCK_INTERVAL_MINUTES;
export const HOURS_IN_A_DAY = 24;


const MARGIN = 8;
const LINE_HEIGHT = 20;
const COLLAPSIBLE_SECTION_HEIGHT = 40;
const LOCATION_TYPE_HEIGHT = 24;

export const TIME_BLOCK_WIDTH = 50;
export const TIME_BLOCK_HEIGHT = Math.floor(
    (document.documentElement.clientHeight - 2 * MARGIN - 5 * LINE_HEIGHT - 3 * COLLAPSIBLE_SECTION_HEIGHT - 3 * MARGIN - LOCATION_TYPE_HEIGHT - 2 * MARGIN - COLLAPSIBLE_SECTION_HEIGHT)
    / (INTERVALS_PER_HOUR * Math.abs(CollapsibleSections[1].endHour - CollapsibleSections[0].startHour))
);

const formatDateToString = (date) => {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}${DATE_HOUR_DELIMITER}${hours}:${minutes}+04:00`;
};

export const formatToShortDateString = (date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
        return "Today";
    } else if (date.toDateString() === yesterday.toDateString()) {
        return "Yesterday";
    } else {
        return date.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric'
        });
    }
};

export const getHourStringFromDateString = (dateString) => {
    if (!dateString) return;
    return dateString.split(DATE_HOUR_DELIMITER)[1].split("+")[0];
};

export const generateEmptyTimeBlocksArray = (surveyDate) => {
    const timeBlocks = [];
    for (let hour = 0; hour < HOURS_IN_A_DAY; hour++) {
        // Find matching collapsible section for current hour
        const section = CollapsibleSections.find(s =>
            hour >= s.startHour && hour < s.endHour
        );

        // Create base date with day offset
        const baseDate = new Date(surveyDate);
        baseDate.setUTCDate(baseDate.getUTCDate() + (section?.addDays || 0));

        for (let interval = 0; interval < INTERVALS_PER_HOUR; interval++) {
            const startMinute = (60 / INTERVALS_PER_HOUR) * interval;
            const endMinute = startMinute + (60 / INTERVALS_PER_HOUR) - 1;

            // Create timeblock dates using adjusted base date
            const startDate = new Date(baseDate);
            startDate.setUTCHours(hour, startMinute, 0, 0);

            const endDate = new Date(baseDate);
            endDate.setUTCHours(hour, endMinute, 59, 999);

            timeBlocks.push({
                i: hour * INTERVALS_PER_HOUR + interval,
                start_date: formatDateToString(startDate),
                end_date: formatDateToString(endDate),
                location_method: "survey",
                location_type: null
            });
        }
    }
    return timeBlocks;
};