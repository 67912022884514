import { green, blue, grey, orange } from '@mui/material/colors';

export const API_URL = process.env.REACT_APP_ENV === 'local-backend' ? 'http://localhost:3001' : process.env.REACT_APP_SERVER_URL;

export const LocationTypes = {
    home: {
        id: "home",
        name: "At home",
        color: green[600]
    },
    indoors: {
        id: "indoors",
        name: "Indoors elsewhere",
        color: orange[600]
    },
    outdoors: {
        id: "outdoors",
        name: "Outdoors",
        color: blue[600]
    },
    uncategorized: {
        id: "uncategorized",
        name: "Uncategorized",
        color: grey[400]
    }
};

export const categorizedLocationTypes = [
    LocationTypes.home,
    LocationTypes.indoors,
    LocationTypes.outdoors
];

export const allLocationTypes = [
    LocationTypes.home,
    LocationTypes.indoors,
    LocationTypes.outdoors,
    LocationTypes.uncategorized
];